import { User } from "./../../../components/models/UserModel";
import { autoinject, bindable } from "aurelia-framework";
import { SingletonService } from "./../../../singleton";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { I18N } from "aurelia-i18n";
import { Router } from "aurelia-router";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { MenuItem } from "components/models/MenuItem";
import { selectAccountIndexAction } from "services/actions/selectAccountIndexAction";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { EventAggregator } from "aurelia-event-aggregator";
import {View} from 'aurelia-templating';
import * as localForage from 'localforage';

@connectTo()
@autoinject()
export class ChooseWallet {
  private blurred: boolean;
  private my_banks: Array<YapilyBankModel> = [];
  private previous: number;
  private accounts = [];
  private no_name;
  private currentIndex: number = -1;
  private walletsOutOfDevice;
  private tabs: number = 0;
  private params;
  private title = "";
  private listMenuItems;
  private selectedAccount: IGenericAccount;
  private isEditing: boolean = false;
  private words: string[] = ["", "", "", "", "", "", "", "", "", "", "", ""];
  private seedPhrase: string = "";
  private myWalletID: Record<string, string>;
  private localForageEntry: { 
    index: string, 
    values: {
     paymail: string;
     seedPhrase: string;
  }};
  private localForagePaymail: string;
  private account: any; // Define account here

  singleWebSocketPaymailInput: string
  lightIndicatorResult: Promise<boolean>;
  wallets: IGenericAccount[] = [];

  // SHOWCONTENT CASES
  private bankorpaymail_editmode_content = [];
  private bankorpaymail_r_u_sure_content = [];
  private bankorpaymail_editmode_boolean: boolean = true;
  private bankorpaymail_r_u_sure_boolean: boolean = true;
  private state: State;
  paymailStatus: boolean;
  @bindable matchResult: boolean;

  hideDisplay: HTMLDivElement;

  constructor(
    private singleton: SingletonService,
    private i18n: I18N,
    private router: Router,
    private store: Store<State>,
    private ea: EventAggregator,

  ) {
  }


  addAccount() {
    this.router.navigateToRoute('choose_type_account');
  }
  
  /**
   * Create Wallet =>
   * 1. generate key new P2PWallet()
   * 2. localForage.setItem(pubkey, privkey): xpub123 => xpriv123
   * 3. this.ea.publish(p2pwallet)
   */

  selectThisAccount(account: IGenericAccount, index: number) {
    this.store.dispatch("account.selected.update", index);
    if (this.state.makePayment.isMK === true) {
      this.store.dispatch("set.make_payment.sender_wallet", account);
      this.store.dispatch("set.make_payment.mk", false);
      this.router.navigateBack();
    } else {
      this.router.navigateToRoute("home");
    }
  }

  fillMenuItems(newState) {
    let nbrWallets = newState.wallets.filter(
      (account) => account.isBlockchain
    ).length;
    let nbrBankAccounts = newState.wallets.filter(
      (account) => !account.isBlockchain
    ).length;
    const nbrTotalAccounts = newState.wallets.length;
    const allAccountsMenuItem = new MenuItem(
      this.i18n.tr("home.all"),
      "",
      nbrTotalAccounts
    );
    const bankAccountsMenuItem = new MenuItem(
      this.i18n.tr("home.banking"),
      "",
      nbrBankAccounts
    );
    const bitcoinWalletsMenuItem = new MenuItem(
      this.i18n.tr("bitcoin.bitcoin"),
      "",
      nbrWallets
    );
    this.listMenuItems = [];
    this.listMenuItems.push(allAccountsMenuItem);
    this.listMenuItems.push(bankAccountsMenuItem);
    this.listMenuItems.push(bitcoinWalletsMenuItem);
    this.title = this.i18n.tr("home.wallets");
  }

  stateChanged(newState, oldState) {
    this.fillMenuItems(newState);
  }

  activate(params) {
    this.params = params;
    this.singleton.setOutOfDeviceWallet(null);
    this.singleton.setCryptoWalletSelected(null);
    this.singleton.setCurrentCryptoWalletIndex(null);
  }

  async unlinkWallet(wallettodelete) {
    try {
      if(wallettodelete.isError) {
        this.ea.publish("wallets.delete.one.backend", wallettodelete);
        this.store.dispatch('banks.delete.one.state' , wallettodelete.account_id)
        this.hideCardContent = false;
      }
      this.getMatchingPaymailAndDelete(wallettodelete.identification)
      this.ea.publish("wallets.delete.one.backend", wallettodelete);
      this.hideCardContent = false;
      return false;
    } catch (error) {
      console.error("An error occurred while removing the wallet:", error);
      return false;
    }
  }

  unlinkBank(banktodelete) {
    this.ea.publish("banks.delete.one.backend", banktodelete);
    this.hideCardContent = false;
    return false;
  }

  firstEdit: boolean = true;
  editModeIndex: number = null;
  unlinkModeIndex: number = null;
  hideCardContent: boolean = false;


  showContent(content_to_show: string, index: number) {
    switch (content_to_show) {
      case "bankorpaymail_editmode":
        this.firstEdit;
        this.editModeIndex = index;
        this.unlinkModeIndex = null;
        this.hideCardContent = false;
        break;
      case "bankorpaymail_r_u_sure":
        console.clear( );
        this.firstEdit;
        this.unlinkModeIndex = index;
        this.editModeIndex = null;
        this.hideCardContent = true;
        break;
    }
  }

  goToBitcoinImport(account: IGenericAccount) {
    if(account.identification.includes("@")) {
      this.router.navigateToRoute("bitcoin_import", {selectedPaymail: account.identification});
    }
    else {
      this.router.navigateToRoute("reauthorize_bank", {selectedAccount: account})
    }
  }

  async getMatchingPaymail(singleWebSocketPaymailInput: string) {
    this.myWalletID = JSON.parse(await localForage.getItem('wallets_' + this.state.me._id)) as Record<string, string>;
    if (this.myWalletID) {
      let match = false; // Initialize a variable to keep track of whether a match has been found

      for (let i = 0; i < Object.keys(this.myWalletID).length; i++) {
        const entryIndex = Object.keys(this.myWalletID)[i];
        const entryValues = Object.values(this.myWalletID)[i];
        const paymail = Object.keys(entryValues).includes(singleWebSocketPaymailInput)
        const seedPhrase =  Object.values(entryValues)

        if (!paymail) {match = false;}
        else 
        if (paymail) {
          match = true; // Set 'found' to true only when a match is found
          break; // Exit the loop since we have already found a match
        }
      }      
      this.matchResult = match;
    }
  }
  

  async getMatchingPaymailAndDelete(singleWebSocketPaymailInput: string) {
    this.myWalletID = JSON.parse(await localForage.getItem('wallets_' + this.state.me._id)) as Record<string, string>;
    if (this.myWalletID) {
      let match = false; // Initialize a variable to keep track of whether a match has been found

      for (let i = 0; i < Object.keys(this.myWalletID).length; i++) {
        const entryIndex = Object.keys(this.myWalletID)[i];
        const entryValues = Object.values(this.myWalletID)[i];
        const paymail = Object.keys(entryValues).includes(singleWebSocketPaymailInput)
        const seedPhrase =  Object.values(entryValues)

        if (!paymail){match = false;} 
        else if (paymail) {
          match = true; 
          // Delete the entry
          const wallets_state_me_id :string = `wallets_${this.state.me._id}`
          const existingWalletData : string = await localForage.getItem(wallets_state_me_id)
          const syntax = new RegExp(`,?{\\"${singleWebSocketPaymailInput}\\":\\"(.*?)\\"},?`);
          let updatedWalletData:string = existingWalletData.replace(syntax, '');
          if (updatedWalletData.includes("}{") || updatedWalletData.includes("},,{" )) {
            updatedWalletData = updatedWalletData.replace("}{", "},{");
          }
          await localForage.setItem(wallets_state_me_id, updatedWalletData).then(function() {})
          break; // Exit the loop since we have already found a match
        } 
      }
      this.matchResult = match;
    }
  }
}
